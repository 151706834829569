import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { useLocation } from 'react-use'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'

import { media } from '../../styles/utils'
import { hoverState } from '../../styles/global'

const Pagination = props => {
    const { slug, index, first, last, pageCount } = props
    const location = useLocation()
    if (!location) return
    // console.log(location)

    const nextUrl = (index + 1).toString()

    const pagination_links_array = new Array(pageCount)
    pagination_links_array.fill({})

    const pagination_links = pagination_links_array.map((item, i) => {
        let linkUrl =
            i === 0
                ? `/${slug}`
                : `/${slug}/${i + 1}`

        return (
            <div key={i} >
                {index === i + 1 && <span>{i + 1}</span>}
                {index !== i + 1 && (
                    <PaginationLink to={linkUrl}>
                        <span>{i + 1}</span>
                    </PaginationLink>
                )}
            </div>
        )
    })

    return (
        <Wrapper>
            {pagination_links}

            {/* Next Link */}
            {!last && (
                <NextLink to={`/${slug}/${nextUrl}/`}>
                    <span>>></span>
                </NextLink>
            )}
            {last && <span>>></span>}
        </Wrapper>
    )
}

const PaginationLink = styled(Link)``
const NextLink = styled(Link)``
const Wrapper = styled.div`
    ${tw`flex justify-center mt-16 mb-8`};

    ${media.mob`
        ${tw`mt-0`};
    `}

    span {
        ${tw`flex justify-center items-center w-12 h-12 mx-1 border-2 border-darkBlue rounded-lg text-darkBlue`};
        font-weight: 600;
        font-size: 1.375rem;

        /* active item */
        ${tw`bg-darkBlue text-white`};
    }

    /* inactive item */
    ${PaginationLink}, ${NextLink} {
        span {
            ${tw`bg-transparent text-darkBlue`};
        }

        ${hoverState};
    }

    /* next page link */
    > span:last-child,
    > a:last-child span {
        ${tw`bg-transparent border-0 text-darkBlue`};
    }
`

Pagination.propTypes = {
    slug: PropTypes.string,
    index: PropTypes.number,
    first: PropTypes.bool,
    last: PropTypes.bool,
    pageCount: PropTypes.number,
}

export default Pagination
