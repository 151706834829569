import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import * as Scroll from 'react-scroll'
let ScrollLink = Scroll.Link
import tw from 'tailwind.macro'
import { media } from '../styles/utils'

import { Layout, PageHeader, News } from '../components'
import { parseACF } from '../utils'
import {
    container,
    padding,
    hoverState,
    button,
    boxShadow,
} from '../styles/global'

const About = props => {
    const data = props.previewData || parseACF(useStaticQuery(query), 'allWordpressInfopages')
    // console.log('data', data)

    const breadcrumbs = {
        heading: {
            title: 'Strive Financial',
            link: '/'
        },
        description: {
            title: 'About Strive Lend',
            link: undefined
        }
    }

    const renderIntro = () => {
        return (
            <Intro>
                <Container>
                    <Grid>
                        <Left>
                            <Heading
                                dangerouslySetInnerHTML={{
                                    __html: data.intro_intro_text,
                                }}
                            />
                            <Description
                                dangerouslySetInnerHTML={{
                                    __html: data.intro_paragraph_text,
                                }}
                            />
                        </Left>
                        <Right>
                            <AnchorLink
                                to={`directors`}
                                smooth={true}
                                // offset={-48}
                                duration={500}
                            >
                                {data.bios_heading}
                            </AnchorLink>

                            {data.show_important_documents && (
                                <AnchorLink
                                    to={`documents`}
                                    smooth={true}
                                    // offset={-48}
                                    duration={500}
                                >
                                    {data.docs_heading}
                                </AnchorLink>
                            )}
                            <Heading
                                dangerouslySetInnerHTML={{
                                    __html: data.sidebar_heading,
                                }}
                            />
                            <Info
                                dangerouslySetInnerHTML={{
                                    __html: data.sidebar_contact_info,
                                }}
                            />
                        </Right>
                    </Grid>
                </Container>
            </Intro>
        )
    }

    const renderBios = () => {
        const bios = data.bios_list.map((item, i) => {
            return (
                <Bio key={i}>
                    <ImageWrapper>
                        <Image
                            key={item.image.id}
                            src={item.image.sizes.medium}
                        >
                            {(src, loading) => {
                                return (
                                    <LoadedImage
                                        src={src}
                                        alt={item.image?.alt}
                                        style={{ opacity: loading ? 0 : 1 }}
                                    />
                                )
                            }}
                        </Image>
                    </ImageWrapper>

                    <Heading
                        dangerouslySetInnerHTML={{
                            __html: item.name,
                        }}
                    />
                    <Subheading
                        dangerouslySetInnerHTML={{
                            __html: item.role,
                        }}
                    />
                    <Description
                        dangerouslySetInnerHTML={{
                            __html: item.text,
                        }}
                    />
                </Bio>
            )
        })

        return (
            <Bios id={`directors`}>
                <Container>
                    <Heading
                        dangerouslySetInnerHTML={{
                            __html: data.bios_heading,
                        }}
                    />
                    <Grid>{bios}</Grid>
                </Container>
            </Bios>
        )
    }

    const renderDocs = () => {
        const docs = data.docs_list.map((item, i) => {
            return (
                <Doc key={i}>
                    <Heading
                        dangerouslySetInnerHTML={{
                            __html: item.title,
                        }}
                    />
                    <Description
                        dangerouslySetInnerHTML={{
                            __html: item.short_text,
                        }}
                    />
                    <DocLink href={`${item.file}`} target="_blank">
                        {item.file_text}
                    </DocLink>
                </Doc>
            )
        })
        return (
            <Docs id={`documents`}>
                <Container>
                    <Heading
                        dangerouslySetInnerHTML={{
                            __html: data.docs_heading,
                        }}
                    />
                    <Grid>{docs}</Grid>
                </Container>
            </Docs>
        )
    }

    return (
        <Layout meta={data && data.seo}>
            {data && (
                <PageHeader
                    breadcrumbs={breadcrumbs}
                    hero_image={data.hero_image ? data.hero_image : undefined}
                    page_heading={
                        data.page_heading ? data.page_heading : undefined
                    }
                    short_intro={
                        data.short_intro ? data.short_intro : undefined
                    }
                />
            )}
            <Wrapper>
                {renderIntro()}
                {renderBios()}
                {data.show_important_documents && renderDocs()}
            </Wrapper>
            {data.news_posts && <News news_posts={data.news_posts} />}
        </Layout>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``
const Button = styled(Link)``

const ImageWrapper = styled.div``
const Image = styled(ProgressiveImage)``
const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`

const Wrapper = styled.div`
    ${tw`bg-white`};
`

const Container = styled.div`
    ${container}
    ${padding}
`

const Grid = styled.div`
    ${tw`flex`};

    ${media.mob`
        ${tw`block`};
    `}
`
const Left = styled.div``
const Right = styled.div``

// Intro

const AnchorLink = styled(ScrollLink)``
const Intro = styled.div`
    ${tw`pt-16 pb-64 bg-lightBlue`};

    ${media.tabPor`
        ${tw`pb-56`};
    `}

    ${media.mob`
        ${tw`pb-16`};
    `}

    ${Left} {
        ${tw`w-2/3 pr-16 text-darkBlue`};

        ${media.tabPor`
            ${tw`pr-8`};
        `}

        ${media.mob`
            ${tw`w-full pr-0`};
        `}

        ${Heading} {
            ${tw`mb-5`};
            font-weight: 500; /* medium */
            font-size: 1.1875rem;
            line-height: 1.27;
        }

        ${Description} {
            font-weight: 300; /* light */
            font-size: 14px;
            line-height: 1.53;
        }
    }

    ${Right} {
        ${tw`w-1/3 pl-16`};

        ${media.tabPor`
            ${tw`pl-8`};
        `}

        ${media.mob`
            ${tw`w-full pl-0`};
        `}

        ${AnchorLink} {
            ${button};
            ${hoverState};
            ${tw`mb-4 p-2 cursor-pointer text-center`};
            min-width: 220px;

            ${media.tabPor`
                min-width: 160px;
            `}

            ${media.mob`
                display: none;
            `}
        }

        ${Heading} {
            ${tw`relative mt-8 pb-4 leading-none text-darkBlue`};
            font-weight: 600; /* semibold */
            font-size: 14px;

            ${media.mob`
                ${tw`mt-12`};
            `}

            &::after {
                content: '';
                ${tw`absolute bottom-0 left-0 w-8 bg-darkBlue`};
                height: 1px;
            }
        }

        ${Info} {
            ${tw`mt-5 text-orange`};
            font-weight: 600; /* semibold */
            font-size: 14px;
            line-height: 1.58;
        }
    }
`

// Bios

const Bio = styled.div``
const Bios = styled.div`
    ${Container} {
        ${tw`-mt-48 pb-24`};

        ${media.tabPor`
            ${tw`-mt-40`};
        `}

        ${media.mob`
            ${tw`mt-0 pt-16 pb-32`};
        `}
    }

    /* section heading */
    ${Container} > ${Heading} {
        ${tw`leading-none text-orange`};
        font-weight: 600; /* semibold */
        font-size: 1.625rem;
    }

    ${Grid} {
        ${tw`flex-wrap mt-12 -mx-8 -mb-16`};

        ${media.mob`
            ${tw`mx-0`};
        `}
    }

    ${Bio} {
        ${tw`w-1/3 mb-16 px-8`};

        ${media.mob`
            ${tw`w-full px-0`};
        `}

        ${ImageWrapper} {
            ${tw`relative`};
            width: 230px;
            height: 230px;

            ${media.tabPor`
                width: 160px;
                height: 160px;
            `}
        }

        ${Image}, ${LoadedImage} {
            ${tw`absolute inset-0 w-full h-full object-cover`};
            border-radius: 50%;
        }

        ${Heading} {
            ${tw`mt-12 leading-none text-orange`};
            font-weight: 600; /* semibold */
            font-size: 1.25rem;

            ${media.mob`
                ${tw`mt-8`};
            `}
        }

        ${Subheading}, ${Description} {
            ${tw`text-darkBlue`};
        }

        ${Subheading} {
            ${tw`mt-6 leading-none`};
            font-weight: 700; /* bold */
            font-size: 14px;
        }

        ${Description} {
            ${tw`mt-4`};
            font-weight: 400; /* regular */
            font-size: 14px;
            line-height: 1.58;

            a {
                font-weight: 700; /* bold */
            }
        }
    }
`

// Docs

const Doc = styled.div``
const DocLink = styled.a``
const Docs = styled.div`
    ${tw`pt-16 pb-32 bg-darkBlue text-white`};

    /* section heading */
    ${Container} > ${Heading} {
        ${tw`text-center leading-none`};
        font-weight: 600; /* semibold */
        font-size: 1.9375rem;
    }

    ${Grid} {
        ${tw`flex-wrap mt-16 -mx-6 -mb-12`};
    }

    ${Doc} {
        ${tw`w-1/4 mb-12 px-6`};

        ${Heading} {
            font-weight: 700; /* bold */
            font-size: 14px;
            line-height: 1.58;
        }

        ${Description} {
            ${tw`mt-4`};
            font-weight: 400; /* regular */
            font-size: 14px;
            line-height: 1.5;
        }

        ${DocLink} {
            ${tw`mt-8`};
            ${button};
            ${hoverState};
        }
    }
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "about-us" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default About
