import React, { useState } from 'react'
import { navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'

import { media } from '../../../styles/utils'

const BlockText = props => {
    const { content } = props
    // console.log(props)

    return (
        <Wrapper>
            {content && (
                <Text
                    className={'tiny-mce'}
                    dangerouslySetInnerHTML={{
                        __html: content,
                    }}
                />
            )}
        </Wrapper>
    )
}

const Wrapper = styled.div``

const Text = styled.div`
    font-weight: 300; /* light */
    font-size: 14px;
    line-height: 1.53;
`

export default BlockText
