import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import * as Scroll from 'react-scroll'
const ScrollLink = Scroll.Link
import tw from 'tailwind.macro'
import { media } from '../styles/utils'

import { Layout, PageHeader, Testimonial, News, Disclaimer } from '../components'
import { parseACF } from '../utils'
import {
    container,
    padding,
    bgImage,
    bgIcon,
    bgClip,
    hoverState,
    button,
    boxShadow,
} from '../styles/global'
import { navigation } from '../styles/type'

import linkArrow from '../assets/icons/link-arrow.svg'

const Index = props => {
    const data = props.previewData || parseACF(useStaticQuery(query), 'allWordpressInfopages')
    // console.log(data)

    const renderIntroBox = () => {
        const introbox_streams = data.introbox_streams.map((item, i) => {
            return (
                <IntroBoxStream key={i}>
                    <Heading
                        dangerouslySetInnerHTML={{
                            __html: item.heading,
                        }}
                    />
                    <Subheading
                        dangerouslySetInnerHTML={{
                            __html: item.intro_text,
                        }}
                    />
                    <Description
                        dangerouslySetInnerHTML={{
                            __html: item.paragraph_text,
                        }}
                    />
                    <StreamLink
                        to={item.heading}
                        spy={true}
                        smooth={true}
                        offset={-48}
                        duration={500}
                    >
                        <span>{`${item.heading} with Strive`}</span>
                    </StreamLink>
                </IntroBoxStream>
            )
        })

        return (
            <Container>
                <IntroBox>
                    <Grid>
                        <Left>
                            <Heading
                                dangerouslySetInnerHTML={{
                                    __html: data.introbox_intro_text,
                                }}
                            />
                            <Description
                                dangerouslySetInnerHTML={{
                                    __html: data.introbox_paragraph_text,
                                }}
                            />
                        </Left>
                        <Right>{introbox_streams}</Right>
                    </Grid>
                </IntroBox>
            </Container>
        )
    }

    const renderBusinessDescription = () => {
        return (
            <BusinessDescription>
                <Container>
                    {data.business_use_badge_icon && (
                        <Image
                            key={data.business_badge_icon.id}
                            src={data.business_badge_icon.sizes.large}
                        >
                            {(src, loading) => {
                                return (
                                    <LoadedImage
                                        src={src}
                                        style={{ opacity: loading ? 0 : 1 }}
                                    />
                                )
                            }}
                        </Image>
                    )}
                    <Heading
                        dangerouslySetInnerHTML={{
                            __html: data.business_intro_text,
                        }}
                    />
                    <Description
                        dangerouslySetInnerHTML={{
                            __html: data.business_paragraph_text,
                        }}
                    />
                    {data.business_use_button_link && (
                        <Button to={`${data.business_button_link}`}>
                            {data.business_button_link_text}
                        </Button>
                    )}
                </Container>
            </BusinessDescription>
        )
    }

    const renderBreakoutBox = item => {
        const list = item.breakout_box_list.map((item, i) => {
            return <ListItem key={i}>{item.item}</ListItem>
        })

        return (
            <BreakoutBox>
                <Heading>{item.breakout_box_heading}</Heading>
                <List>{list}</List>
                <Button to={`${item.breakout_box_link}`}>
                    {item.breakout_box_link_text}
                    <Arrow src={linkArrow} />
                </Button>
            </BreakoutBox>
        )
    }

    const renderStreamSections = () => {
        const streams = data.streams.map((item, i) => {
            return (
                <Stream key={i} id={`${item.heading}`}>
                    <Container>
                        <Grid>
                            <Main even={(i + 1) % 2 == 0 ? 'true' : 'false'}>
                                <Heading>
                                    <span>{item.heading}</span>
                                </Heading>
                                <Subheading
                                    dangerouslySetInnerHTML={{
                                        __html: item.sub_heading,
                                    }}
                                />
                                <Info
                                    dangerouslySetInnerHTML={{
                                        __html: item.intro_text,
                                    }}
                                />
                                <Description
                                    dangerouslySetInnerHTML={{
                                        __html: item.paragraph_text,
                                    }}
                                />
                                <ExtraLinks>
                                    {item.extra_links.map((item, i) => {
                                        return (
                                            <ExtraLink
                                                key={i}
                                                to={`${item.link}`}
                                            >
                                                {item.text}
                                            </ExtraLink>
                                        )
                                    })}
                                </ExtraLinks>
                            </Main>
                            {renderBreakoutBox(item)}
                        </Grid>
                    </Container>
                </Stream>
            )
        })

        return <StreamSections>{streams}</StreamSections>
    }

    return (
        <Layout meta={data && data.seo}>
            {data && (
                <PageHeader
                    page_heading={
                        data.page_heading ? data.page_heading : undefined
                    }
                    short_intro={
                        data.short_intro ? data.short_intro : undefined
                    }
                    hero_image={data.hero_image ? data.hero_image : undefined}
                    page={'home'}
                />
            )}

            {renderIntroBox()}
            {renderBusinessDescription()}
            {renderStreamSections()}

            <Testimonial testimonials={data.testimonials} />
            <News news_posts={data.news_posts} page={'home'} />
            {data.show_disclaimer_text && (
                <Disclaimer disclaimer_text={data.disclaimer_text}/>
            )}
        </Layout>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``
const Button = styled(Link)``

const Image = styled(ProgressiveImage)``

const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`

const Container = styled.div`
    ${container}
    ${padding}
`

const Grid = styled.div`
    ${tw`flex`};

    ${media.mob`
        ${tw`flex-col`};
    `}
`
const Left = styled.div``
const Right = styled.div``

// Intro Box

const IntroBox = styled.div`
    ${tw`relative z-10 -mt-32 p-16 bg-white text-darkBlue`};
    
    ${media.mob`
        ${tw`pt-12 px-8`};
    `}

    ${Grid} {
        ${media.tabPor`
            ${tw`flex-wrap`};
        `}
    }

    ${Left} {
        ${tw`w-1/3 pr-20`};

        ${media.tabPor`
            ${tw`w-full pr-0`};
        `}

        ${Heading} {
            font-weight: 500; /* medium */
            font-size: 1.25rem;
            line-height: 1.22;
        }

        ${Description} {
            ${tw`mt-6`};
            font-weight: 300; /* light */
            font-size: 14px;
            line-height: 1.31;
        }
    }

    ${Right} {
        ${tw`w-2/3 flex`};

        ${media.tabPor`
            ${tw`w-full mt-10`};
        `}

        ${media.mob`
            ${tw`flex-col mt-0`};
        `}
    }
`

// Intro Box - Stream Section Summaries

const StreamLink = styled(ScrollLink)``
const IntroBoxStream = styled.div`
    ${tw`w-1/2`};

    ${media.mob`
        ${tw`w-full`};
    `}

    &:first-child {
        ${tw`pr-16 border-r border-orange`};

        ${media.tabPor`
            ${tw`pr-12`};
        `}

        ${media.mob`
            ${tw`pr-0 mt-12 border-none`};
        `}
    }

    &:last-child {
        ${tw`pl-16`};

        ${media.tabPor`
            ${tw`pl-12`};
        `}

        ${media.mob`
            ${tw`mt-12 pl-0`};
        `}
    }

    ${Heading} {
        ${tw`text-orange`};
        font-weight: 600; /* semibold */
        font-size: 1.8125rem;
        letter-spacing: -0.02em;
    }

    ${Subheading} {
        ${tw`mt-6`};
        font-weight: 500; /* medium */
        font-size: 16px;
        line-height: 1.27;
    }

    ${Description} {
        ${tw`mt-6`};
        font-weight: 300; /* light */
        font-size: 14px;
        line-height: 1.31;
    }

    ${StreamLink} {
        ${bgClip};
        ${hoverState};
        ${tw`w-full mt-8 py-2 cursor-pointer`};
        font-weight: 600; /* semibold */
        font-size: 16px;
        letter-spacing: -0.02em;
    }
`

// Business Description

const BusinessDescription = styled.div`
    ${tw`pt-24 pb-32 text-center`};

    ${media.mob`
        ${tw`py-16`};
    `}

    ${Container} {
        ${tw`px-32`};

        ${media.mob`
            padding-left: 20px;
            padding-right: 20px;
        `}
    }

    ${Image}, img {
        ${tw`mx-auto`};
    }

    ${Heading}, ${Description} {
        ${tw`text-white`};
    }

    ${Heading} {
        ${tw`mt-10`};
        font-weight: 500; /* medium */
        font-size: 1.25rem;
        line-height: 1.22;
    }

    ${Description} {
        ${tw`mt-6`};
        font-weight: 400; /* regular */
        font-size: 14px;
        line-height: 1.58;
    }

    ${Button} {
        ${tw`mt-12`};
        ${button};
    }
`

// Stream Sections Layout

const StreamSections = styled.div``
const Stream = styled.div`
    ${tw`w-full pt-12 pb-48 bg-lightBlue text-darkBlue`};

    ${media.mob`
        ${tw`pb-24`};
    `}

    &:nth-child(even) {
        ${tw`bg-darkBlue text-white`};
    }

    ${Grid} {
        ${tw`justify-between`};
    }
`

// Stream Section Main

const ExtraLinks = styled.div``
const ExtraLink = styled(Link)``
const Main = styled.div`
    ${tw`w-3/5 pr-12`};

    ${media.mob`
        ${tw`w-full pr-0`};
    `}

    ${props => {
        if (props.even === 'true')
            return css`
                ${tw`order-last pr-0 pl-12`};

                ${media.mob`
                    ${tw`order-first pl-0`};
                `}
            `
    }}

    ${Heading} {
        font-weight: 600; /* semibold */
        font-size: 2.0625rem;
        ${bgClip};
        ${tw`w-64 p-4`};
    }
    ${Subheading} {
        ${tw`mt-12`};
        font-weight: 600; /* semibold */
        font-size: 1.375rem;
        letter-spacing: -0.02em;
    }

    ${Info} {
        ${tw`mt-6`};
        font-weight: 500; /* medium */
        font-size: 16px;
        line-height: 1.27;
    }

    ${Description} {
        ${tw`mt-6`};
        font-weight: 300; /* light */
        font-size: 14px;
        line-height: 1.53;
    }

    ${ExtraLinks} {
        ${tw`flex mt-12 -mx-4`};

        ${media.tabPor`
            ${tw`flex-col`};
        `}
    }

    ${ExtraLink} {
        ${tw`inline-block mx-4 py-3 border-b border-orange text-orange`};
        font-weight: 600; /* semibold */
        font-size: 15px;
        letter-spacing: -0.02em;
        ${hoverState};
    }
`

// Stream Section BreakoutBoxes

const Arrow = styled.img``
const List = styled.ul``
const ListItem = styled.li``
const BreakoutBox = styled.div`
    ${tw`flex flex-col max-w-sm -mt-24 p-12 pb-0 bg-orange`};
    ${boxShadow};

    ${media.tabPor`
        align-self: flex-start;
    `}

    ${media.mob`
        ${tw`w-full mt-16`};
        max-width: initial;
    `}

    ${Heading} {
        ${tw`text-white`};
        font-weight: 600; /* semibold */
        font-size: 1.8125rem;
        line-height: 1.034;
    }

    ${List} {
        ${tw`my-12 pl-5 list-disc text-white`};
    }

    ${ListItem} {
        ${tw`py-2`};
        font-weight: 500; /* medium */
        font-size: 17px;
        line-height: 1.44;
    }

    ${Button} {
        ${tw`flex justify-between items-center mt-auto p-4 border-t border-white leading-tight text-white`};
        font-weight: 600; /* semibold */
        font-size: 1.1875rem;
        letter-spacing: -0.02em;
        ${hoverState};
    }
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "home" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Index
